@tailwind base;
@tailwind components;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  padding: 8px 12px;
  width: 260px;
}

button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #2196F3;
  height: 50px;
  width: 200px;
  font-weight: bold;
  color: white;
  cursor: pointer;

  @apply hover:bg-blue-500
}
.blue-btn {
  @apply bg-blue-500 hover:bg-blue-700
}
.green-btn {
  @apply bg-green-500 hover:bg-green-700
}
.gray-btn {
  @apply bg-gray-400 hover:bg-gray-600
}
.red-btn {
  @apply text-red-500 bg-white border border-red-500 hover:bg-red-500 hover:text-white
}
.white-btn {
  @apply text-gray-800 bg-white border border-black hover:bg-gray-100
}
.small-btn {
  @apply h-[42px] px-[18px] w-auto
}

.list-link {
 @apply text-blue-500 underline decoration-blue-500
}

h1 {
  @apply text-3xl font-bold mb-6 mt-4
}
h2 {
  @apply text-2xl font-bold mb-4
}
select {
  @apply h-[42px] w-[240px] border rounded px-2
}
textarea {
  @apply h-[300px] w-[540px] border rounded p-2
}

@tailwind utilities;
